<template>
  <div class="flex flex-row items-center justify-center">
    <div 
      :class="['flex', 'items-center', 'justify-center', 'number', {active: item + 1 === currentPage}]" 
      v-for="(item, index) in pageNumbers"
      @click="onPage(item + 1)"
      :key="index">
      {{ item + 1 }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: Number,
    lastPage: Number,
    onPage: Function,
  },
  computed: {
    pageNumbers: function() {
      return [...new Array(this.lastPage).keys()];
    },
  },
}
</script>

<style scoped lang="scss">
.number {
  cursor: pointer;
  width: 23px;
  height: 23px;
  border-radius: 3px;
  border: solid 1px #c1c1c1;
  background-color: #fff;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.6px;
  text-align: center;
  color: #939393;

  &.active {
    background-color: #283aef;
    color: #fff;
    border: 0;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }
}
</style>