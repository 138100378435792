<template>
  <div class="w-full header flex flex-row items-center justify-center">
    <div class="container flex flex-row items-center justify-between">
      <h4>{{ title }}</h4>

      <div class="flex flex-row items-center">
        <slot>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeaderLayout',
  props: {
    title: String,
  },
}
</script>

<style lang="scss" scoped>
  .header {
    height: 60px;
    border-bottom: 1px solid rgba(193, 193, 193, 0.2);

    h4 {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.9px;
      text-align: left;
      color: #353535;
    }
  }

  @media (max-width: 639px) {
    .header {
      h4 {
        font-size: 14px;
      }
    }
  }
</style>