<template>
  <div class="flex flex-row items-center justify-between favorite-item-wrapper">
    <div class="favorite-item flex flex-1 h-full">
      <InteriorSearchItem :data="data" :hasInfo="false" />
    </div>

    <div class="flex flex-col button-group">
      <button @click="goToDetail" class="flex justify-center items-center">
        상세보기
      </button>
      <button
        @click="removeInteriorFavorite"
        class="flex justify-center items-center delete"
      >
        삭제
      </button>
    </div>
  </div>
</template>

<script>
import InteriorSearchItem from "@/components/InteriorSearchItem.vue";
import { mapState } from "vuex";
import services from "@/services";

export default {
  name: "InteriorFavoriteItem",
  components: { InteriorSearchItem },
  props: {
    data: Object,
    onRemove: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState("user", ["userId"]),
  },
  methods: {
    goToDetail() {
      if (this.data.id) {
        this.$router.push({
          name: "InteriorOfficeDetail",
          params: { id: this.data.id },
        });
      }
    },
    async removeInteriorFavorite() {
      await services.interiorRemoveSubscribe(this.data.id);
      this.$toast.open("찜해제 되었습니다");
      this.onRemove();
    },
  },
};
</script>

<style scoped lang="scss">
.favorite-item-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
}

.button-group {
  button {
    width: 135px;
    height: 46px;
    border-radius: 10px;
    background-color: #bfbfbf;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: left;
    color: #fff;

    &:not(:last-child) {
      margin-bottom: 9px;
    }

    &.delete {
      background-color: #e05050;
    }
  }
}

@media (max-width: 639px) {
  .favorite-item-wrapper {
    flex-direction: column;
    .favorite-item {
      width: 100%;
    }
  }
  .button-group {
    width: 100%;
    max-width: 320px;
    margin-top: 15px;
    button {
      height: 35px;
      font-size: 13px;
      width: 100%;
    }
  }
}
</style>
