<template>
  <JumbotronLayout>
    <PageHeaderLayout :title="'나의 찜 내역'">
      <div
        @click="() => onChangeType('place')"
        :class="['header-item', { active: type === 'place' }]"
      >
        등록공간 찜 ({{ placeTotal }})
      </div>
      <span class="header-space">|</span>
      <div
        @click="() => onChangeType('interior')"
        :class="['header-item', { active: type === 'interior' }]"
      >
        인테리어 찜 ({{ interiorTotal }})
      </div>
    </PageHeaderLayout>

    <div class="w-full flex justify-center items-center">
      <div class="container">
        <PaginationLayout
          :hasData="
            (type === 'place' && placeFavorites.length > 0) ||
              (type === 'interior' && interiorFavorites.length > 0)
          "
          :lastPage="lastPage"
          :onPage="onPage"
        >
          <div v-if="type === 'place'">
            <PlaceFavoriteItem
              v-for="item in placeFavorites"
              :onRemove="onRemovePlaceFavorite"
              :key="item.id"
              :data="item"
            />
          </div>
          <div v-else>
            <InteriorFavoriteItem
              v-for="item in interiorFavorites"
              :onRemove="onRemoveInteriorFavorite"
              :key="item.id"
              :data="item"
            />
          </div>
        </PaginationLayout>
      </div>
    </div>
  </JumbotronLayout>
</template>

<script>
import JumbotronLayout from "@/components/JumbotronLayout.vue";
import PageHeaderLayout from "@/components/PageHeaderLayout.vue";
import PlaceFavoriteItem from "@/components/PlaceFavoriteItem.vue";
import InteriorFavoriteItem from "@/components/InteriorFavoriteItem.vue";
import PaginationLayout from "@/components/PaginationLayout.vue";
import services from "@/services";
import { mapState } from "vuex";

export default {
  name: "Favorite",
  components: {
    JumbotronLayout,
    PageHeaderLayout,
    PlaceFavoriteItem,
    InteriorFavoriteItem,
    PaginationLayout,
  },
  data() {
    return {
      type: "place",
      placeFavorites: [],
      interiorFavorites: [],
      loading: false,
      currentPage: 1,
      lastPage: 1,
      placeTotal: 0,
      interiorTotal: 0,
    };
  },
  mounted() {
    this.getPlaceFavorite();
    this.getInteriorFavorite();
  },
  methods: {
    onChangeType(value) {
      this.type = value;
    },
    onPage: function(value) {
      this.currentPage = value;
      if (this.type === "place") {
        this.getPlaceFavorite();
      } else {
        this.getInteriorFavorite();
      }
    },
    async getPlaceFavorite() {
      const isPlace = this.type === "place";
      const { data, total, lastPage } = await services.placeList({
        page: isPlace ? this.currentPage : 1,
        paginate: 5,
        subscribeUserId: this.userId,
      });
      this.placeFavorites = data;
      this.placeTotal = total;
      this.lastPage = lastPage;
    },
    async getInteriorFavorite() {
      const isInterior = this.type !== "place";
      const { data, total, lastPage } = await services.interiorOfficeList({
        page: isInterior ? this.currentPage : 1,
        paginate: 5,
        subscribeUserId: this.userId,
      });
      this.interiorFavorites = data;
      this.interiorTotal = total;
      this.lastPage = lastPage;
    },
    onRemovePlaceFavorite() {
      this.getPlaceFavorite();
    },
    onRemoveInteriorFavorite() {
      this.getInteriorFavorite();
    },
  },
  computed: {
    ...mapState("user", ["userId"]),
  },
  watch: {
    type() {
      this.currentPage = 1;
      this.lastPage = 1;
    },
  },
};
</script>

<style scoped lang="scss">
.header-item {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.56px;
  text-align: left;
  color: #acb0bb;
  cursor: pointer;

  &.active {
    color: #293bf0;
  }
}

.header-space {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.56px;
  text-align: left;
  color: #acb0bb;
  opacity: 0.6;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination-wrapper {
  margin-top: 50px;
  margin-bottom: 80px;
}
</style>
