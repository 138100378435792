<template>  
  <div class="wrapper flex flex-row items-start justify-between w-full">
    <div
      @click="goToDetail"
      class="image"
      :style="{
        backgroundImage: image ? `url(${image})` : '',
      }"
    />
    <div class="content flex-1">
      <p @click="goToDetail" class="name">{{ data.title }}</p>
      <p @click="goToDetail" class="place">{{ data.addr }}</p>
    </div>
  </div>    
</template>

<script>
import utils from '@/utils';

export default {
  name: "InteriorSearchItem",
  props: {
    data: {
      type: Object,
      default: () => ({
        addr: '',
        title: '',
        hasThumbnail: {}
      })
    },
    hasInfo: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    goToDetail() {
      this.$router.push({ name: 'PlaceDetail', params: { id: this.data.id }});
    }
  },
  computed: {
    image: function() {
      const {hasThumbnail} = this.data;
      if (hasThumbnail) {
        return utils.getImageUrl(hasThumbnail);
      }
      return ''
    }
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  height:  137px;
  cursor: pointer;
  padding-left: 17px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 12px;
  margin-bottom: 12px;

  .image {
    width: 192px;
    height: 137px;
    background-color: #ddd;
    border-radius: 10px;
    margin-right: 23px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    .content-tag {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.64px;
      text-align: left;
      color: #7ca3aa;
    }

    .content-header {
      margin-right: 47px;
    }

    .name {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.8px;
      text-align: left;
      color: #222;
    }

    .place, .size {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.56px;
      text-align: left;
      color: #737373;
    }

    .price {
      margin-top: 14px;

      span:first-child {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        color: #616161;
        margin-right: 8px;
      }

      span:last-child {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.72px;
        text-align: left;
        color: #101010;
      }
    }
  }
}

@media (max-width: 639px) {
  .wrapper {
    flex-direction: column;
    height: auto;
    padding-bottom: 0;
    padding-left: 0;
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;
    .image {
      width: 150px;
      height: 100px;
      margin: 0 auto 15px;
    }
    .content {
      .name {
        font-size: 13px;
      }
      .place {
        font-size: 12px;
      }
    }
  }
}
</style>
