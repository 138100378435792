<template>
  <div>
    <slot></slot>

    <div class="pagination-wrapper" v-if="hasData">
      <Pagination
        :currentPage="currentPage"
        :lastPage="lastPage"
        :onPage="onChange"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";

export default {
  name: "PaginationLayout",
  components: { Pagination },
  props: {
    hasData: {
      type: Boolean,
      default: true,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    onPage: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    onChange(value) {
      this.currentPage = value;
      this.onPage(value);
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-wrapper {
  margin-top: 50px;
  margin-bottom: 80px;
}
</style>
